
import { Controller } from "stimulus"

export default class extends Controller {
  modalContainerTarget: Element
  tabTargets: HTMLElement[]
  tabContentTargets: HTMLElement[]
  static targets = ['modalContainer', 'tab', 'tabContent'];

  connect() {
    this.toggleContent(this.data.get('defaultContent'))
  }

  dismissModal() {
    this.modalContainerTarget.classList.remove("is-active")
  }

  showModal(event) {
    event.preventDefault();
    this.modalContainerTarget.classList.add("is-active")
  }

  showContent(event) {
    const targetContent = event.currentTarget.dataset.targetContent;
    this.toggleContent(targetContent)
  }

  toggleContent(targetContent) {
    this.tabTargets.forEach((el) => el.classList.remove('is-active'));
    this.tabTargets.find(tab => tab.dataset.targetContent == targetContent).classList.add('is-active');
    this.tabContentTargets.forEach((el) => el.classList.add('is-hidden'));
    this.tabContentTargets.find(tabContent => tabContent.dataset.contentName == targetContent).classList.remove('is-hidden');
  }
}
