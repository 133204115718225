import { Controller } from "stimulus"

export default class extends Controller {
  sourceTarget: HTMLElement;
  static targets = ['source'];

  copy(event) {
    event.preventDefault()
    this.sourceTarget.classList.remove('clipboard-source-hide');
    const range = document.createRange()
    range.selectNode(this.sourceTarget);
    window.getSelection().addRange(range);
    document.execCommand("copy")
    this.sourceTarget.classList.add('clipboard-source-hide');
  }
}
