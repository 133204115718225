
import { Controller } from "stimulus"

export default class extends Controller {
  templateTargets: Element[]
  dropdownTarget: Element
  formTarget: HTMLFormElement
  static targets = ['template', 'dropdown', 'form'];

  toggle(event) {
    event.preventDefault()
    this.dropdownTarget.classList.toggle("is-active")
  }
  update(event) {
    event.preventDefault()
    let updateTemplateIndex = 0;
    this.templateTargets.forEach((element, i) => {
      if (element == event.target) {
        updateTemplateIndex = i;
      }
    });
    this.formTarget.value = this.data.get('templates').split(',')[updateTemplateIndex];
    this.dissmissDropdown();
  }
  dismiss(event) {
    if (!event.relatedTarget || !event.relatedTarget.classList.contains('dropdown-item')) {
      this.dissmissDropdown();
    }
  }
  dissmissDropdown() {
    this.dropdownTarget.classList.remove("is-active")
  }
  
}
