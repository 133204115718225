import { Controller } from "stimulus"

export default class extends Controller {
  formTarget: HTMLFormElement;
  labelTarget: HTMLElement;
  submited = false;
  static targets = ['form', 'label'];

  submit() {
    if (!this.submited) {
      this.submited = true;
      this.labelTarget.innerHTML = '更新中';
      this.labelTarget.setAttribute('disabled', 'true');
      this.formTarget.submit();
    }
  }

  preventDoubleClick(event: Event) {
    if (this.submited) {
      event.preventDefault();
    }
  }
}
