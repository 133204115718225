import { Controller } from "stimulus";
import { StimulusEvent } from "../../typing/stimulus";

export default class extends Controller {
  dialogTarget: HTMLDivElement;
  jobCategoryId = "";
  static targets = ["dialog"]
  connect() {
    this.jobCategoryId = this.data.get("jobCategoryId");
  }
  updateJobCategory({ target }: StimulusEvent) {
    console.log(target.value)
    if (this.jobCategoryId === target.value) {
      this.dialogTarget.classList.add("is-active");
    }
  }
  dismissModal() {
    this.dialogTarget.classList.remove("is-active");

  }
}
