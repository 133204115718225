import { Controller } from "stimulus";

export default class extends Controller {
  modalContainerTarget: Element;
  tmpPartyIdRadioButtonTargets: HTMLInputElement[];
  partyIdRadioButtonTarget: HTMLInputElement;
  emptyDescriptionTarget: HTMLElement;
  filledInDescriptionTarget: HTMLElement;
  filledInPartyNameTarget: HTMLElement;
  filledInPartyUrlTarget: HTMLAnchorElement;
  static targets = [
    "modalContainer",
    "tmpPartyIdRadioButton",
    "partyIdRadioButton",
    "emptyDescription",
    "filledInDescription",
    "filledInPartyName",
    "filledInPartyUrl"
  ];
  connect() {
    if (this.partyIdRadioButtonTarget.value === "") {
      this.filledInDescriptionTarget.style.display = "none";
    } else {
      const filledInRadioButton = this.tmpPartyIdRadioButtonTargets.find(
        radioButton => radioButton.value === this.partyIdRadioButtonTarget.value
      );
      if (filledInRadioButton) {
        this.showFilledInDescription(filledInRadioButton);
      }
    }
  }
  dismissModal(event?: Event) {
    if (event) {
      event.preventDefault();
    }
    this.tmpPartyIdRadioButtonTargets.forEach(
      radioButton => (radioButton.checked = false)
    );
    this.modalContainerTarget.classList.remove("is-active");
  }

  showModal(event: Event) {
    event.preventDefault();
    if (this.partyIdRadioButtonTarget.value) {
      const tmpPartyIdRadioButtonTarget = this.tmpPartyIdRadioButtonTargets.find(
        radioButton => radioButton.value === this.partyIdRadioButtonTarget.value
      );
      if (tmpPartyIdRadioButtonTarget) {
        tmpPartyIdRadioButtonTarget.checked = true;
      }
    }
    this.modalContainerTarget.classList.add("is-active");
  }

  updateParty(event: Event) {
    event.preventDefault();
    const checkedRadioButton = this.tmpPartyIdRadioButtonTargets.find(
      radioButton => radioButton.checked
    );
    if (checkedRadioButton) {
      this.partyIdRadioButtonTarget.value = checkedRadioButton.value;
      this.showFilledInDescription(checkedRadioButton);
    }
    this.dismissModal();
  }

  showFilledInDescription(checkedRadioButton: HTMLInputElement) {
    this.filledInDescriptionTarget.style.display = "flex";
    this.emptyDescriptionTarget.style.display = "none";
    this.filledInPartyNameTarget.innerText =
      checkedRadioButton.dataset.partyTitle;
    this.filledInPartyUrlTarget.href = checkedRadioButton.dataset.partyUrl;
    this.filledInPartyUrlTarget.innerText = `${location.origin}/${checkedRadioButton.dataset.partyUrl}`;
  }
}
