import { Controller } from "stimulus";

export default class extends Controller {
  containerTarget: Element;
  contentOuterTarget: HTMLElement;
  contentInnerTarget: HTMLElement;
  scrollHelpTarget: HTMLElement;
  hasScrollHelpTarget: boolean;
  hasContentInnerTarget: boolean;
  hasContentTarget: boolean;
  htmlNode: HTMLElement;
  bodyNode: HTMLElement;
  enterScrollHelperTimer: number;
  scrollX: number;
  scrollY: number;
  static targets = ["container", "contentOuter", "contentInner", "scrollHelp"];
  connect() {
    this.htmlNode = document.documentElement;
    this.bodyNode = document.body;
    this.prepareScrollHelp();
    this.presentScrollHelp();
    if (
      this.hasContentTarget &&
      this.containerTarget.classList.contains("is-active")
    ) {
      this.hiddenBackgoundOverflowContent();
    }
  }
  presentScrollHelp() {
    if (!this.hasScrollHelpTarget) {
      return;
    }
    if (this.isOverflowContent()) {
      this.contentOuterTarget.style.height = "100%";
      this.contentInnerTarget.style.height = "100%";
      this.contentInnerTarget.style.overflow = "scroll";
      this.enterScrollHelperTimer = window.setTimeout(() => {
        if (this.contentInnerTarget.scrollTop == 0) {
          this.scrollHelpTarget.classList.add("is-active", "is-enter");
        }
      }, 1500);
    }
  }
  prepareScrollHelp() {
    if (!this.hasScrollHelpTarget) {
      return;
    }
    this.scrollHelpTarget.addEventListener(
      this.whichAnimationEvent(),
      (event: AnimationEvent) => {
        if (event.animationName == "slide-out-bottom") {
          this.scrollHelpTarget.classList.remove("is-active", "is-leave");
        }
        if (event.animationName == "slide-in-bottom") {
          this.scrollHelpTarget.classList.remove("is-enter");
        }
      }
    );
    this.contentInnerTarget.addEventListener("scroll", () => {
      if (this.scrollHelpTarget.classList.contains("is-active")) {
        this.scrollHelpTarget.classList.add("is-leave");
      }
    });
  }
  dismiss(event: Event) {
    event.preventDefault();
    if (this.hasContentInnerTarget) {
      this.contentInnerTarget.scrollTo(0, 0);
    }
    this.revertHiddenBackgoundOverflowContent();
    if (this.hasScrollHelpTarget) {
      this.scrollHelpTarget.classList.remove(
        "is-active",
        "is-enter",
        "is-leave"
      );
    }
    if (this.enterScrollHelperTimer) {
      clearTimeout(this.enterScrollHelperTimer);
    }
    this.containerTarget.classList.remove("is-active");
    window.scrollTo(this.scrollX, this.scrollY);
  }

  form_copy() {
    let nodelist: NodeList = document
      .getElementById("confirm_mail")
      .querySelectorAll("[name^=search_filter]");
    let target = Array.from(nodelist) as HTMLInputElement[];
    target.forEach((to) => {
      let from: HTMLInputElement = <HTMLInputElement>(
        document.getElementById(
          to.id.replace("search_filter", "general_mail_content")
        )
      );
      if (from.type == "checkbox") {
        to.value = from.checked ? "1" : "0";
      } else {
        to.value = from.value;
      }
    });
  }

  show(event: Event) {
    event.preventDefault();
    // mails/new 用：「送信対象除外」処理でのメール内容の引き継ぎ
    if ("target" in event && "id" in event.target) {
      if (event.target["id"] === "confirm_mail_button") {
        this.form_copy();
      }
    }
    this.scrollX = window.scrollX;
    this.scrollY = window.scrollY;
    this.containerTarget.classList.add("is-active");
    this.presentScrollHelp();
    this.hiddenBackgoundOverflowContent();
  }

  exclude_send(event: Event) {
    event.preventDefault();
    this.form_copy();
    document.forms["confirm_mail_form"].submit();
  }

  whichAnimationEvent() {
    const el = document.createElement("fakeelement");
    const transitions = {
      animation: "animationend",
      OAnimation: "oAnimationEnd",
      MozAnimation: "animationend",
      WebkitAnimation: "webkitAnimationEnd",
    };

    for (let t in transitions) {
      if (el.style[t] !== undefined) {
        return transitions[t];
      }
    }
  }

  hiddenBackgoundOverflowContent() {
    this.htmlNode.style.height = "100%";
    this.bodyNode.style.height = "100%";
    this.bodyNode.style.overflow = "hidden";
  }

  revertHiddenBackgoundOverflowContent() {
    this.htmlNode.style.height = "";
    this.bodyNode.style.height = "";
    this.bodyNode.style.overflow = "";
  }

  isOverflowContent() {
    return (
      this.contentOuterTarget.clientHeight -
        this.contentInnerTarget.scrollHeight <
      0
    );
  }
}
