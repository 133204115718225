import { Controller } from "stimulus";

export default class extends Controller {
  planInputTargets: HTMLInputElement[];
  mailBodyTextareaTarget: HTMLTextAreaElement;
  static targets = ["planInput", "mailBodyTextarea"];

  planChanged(event: Event) {
    if (event.target instanceof HTMLInputElement) {
      this.mailBodyTextareaTarget.value = event.target.dataset.mailBodyTemplate
      this.mailBodyTextareaTarget.dispatchEvent(new Event('change'));
    }
  }
}
