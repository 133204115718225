import { Controller } from "stimulus"

export default class extends Controller {
  checkedTarget: HTMLElement;
  uncheckedTarget: HTMLElement;
  checkedFormTarget: HTMLInputElement;
  hasCheckedFormTarget: boolean;
  static targets = ['checked', 'unchecked', 'checkedForm'];

  connect() {
    this.toggle(this.data.get('initialState') == 'true');
  }
  check() {
    this.uncheckedTarget.style.display = 'none';
    this.checkedTarget.style.display = 'inline-flex';
    if (this.hasCheckedFormTarget) {
      this.checkedFormTarget.value = 'true';
    }
  }

  uncheck() {
    this.uncheckedTarget.style.display = 'inline-flex';
    this.checkedTarget.style.display = 'none';
    if (this.hasCheckedFormTarget) {
      this.checkedFormTarget.value = 'false';
    }
  }
  
  toggle(check: boolean) {
    if (check) {
      this.check();
    }
    else {
      this.uncheck();
    }


  }
}
