import { Controller } from "stimulus";

function isCheckbox(arg: any): arg is HTMLInputElement {
  return arg instanceof HTMLInputElement;
}

export default class extends Controller {
  birthdateSelectTargets: HTMLSelectElement[];
  inputNoteTarget: HTMLDivElement;
  discardBirthdayTarget: HTMLInputElement;

  static targets = ["birthdateSelect", "inputNote", "discardBirthday"];

  connect() {
    this.inputNoteTarget.style.display = "none";
    if (this.discardBirthdayTarget.checked) {
      this.discardDay();
    } else {
      this.registerDay(false);
    }
  }
  discardDay() {
    this.inputNoteTarget.style.display = "block";
    const parentElement = this.daySelectForm().parentElement;
    if (parentElement.classList.contains("select")) {
      parentElement.style.display = "none";
    } else {
      this.daySelectForm().style.display = "none";
    }
    this.daySelectForm().value = this.data.get("psedoBirthday")
  }
  registerDay(resetBirthday = true) {
    this.daySelectForm().parentElement.style.display = "";
    this.daySelectForm().style.display = "";
    this.inputNoteTarget.style.display = "none";
    if (resetBirthday) {
      this.daySelectForm().value = "";
    }
  }
  daySelectForm() {
    return this.birthdateSelectTargets[2];
  }
  toggleDay(event: Event) {
    if (isCheckbox(event.target)) {
      if (event.target.checked) {
        this.discardDay();
      } else {
        this.registerDay();
      }
    }
  }
}
