import { Controller } from "stimulus";

export default class extends Controller {
  submitButtonTarget: HTMLButtonElement;
  excludeCheckboxTargets: HTMLInputElement[];
  static targets = ["submitButton", "excludeCheckbox"];
  connect() {
    this.submitButtonTarget.disabled = true;
  }
  changeExcludeStatus() {
    const checkedTargets = this.excludeCheckboxTargets.filter(
      (target) => target.checked
    );
    this.submitButtonTarget.disabled = checkedTargets.length == 0;
  }
}
