
import { Controller } from "stimulus"

// Return value of Node.compareDocumentPosition()
// document -> https://developer.mozilla.org/en-US/docs/Web/API/Node/compareDocumentPosition
const DOCUMENT_POSITION_PRECEDING = 2
const DOCUMENT_POSITION_FOLLOWING = 4
export default class extends Controller {
  dragstart(event) {
    event.dataTransfer.setData("application/drag-key", event.target.getAttribute("data-about-me-question-id"));
    event.dataTransfer.effectAllowed = "move";
  }
  dragover(event) {
    event.preventDefault();
    return true;
  }

  dragenter(event) {
      event.preventDefault();
  }
  drop(event) {
    var data = event.dataTransfer.getData("application/drag-key");
    const dropTarget = event.target;
    const draggedItem = this.element.querySelector(`[data-about-me-question-id='${data}']`);
    const positionComparison = dropTarget.compareDocumentPosition(draggedItem);
    if ( positionComparison & DOCUMENT_POSITION_FOLLOWING) {
      dropTarget.insertAdjacentElement('beforebegin', draggedItem);
    } else if ( positionComparison & DOCUMENT_POSITION_PRECEDING) {
      dropTarget.insertAdjacentElement('afterend', draggedItem);
    }
    event.preventDefault();
  }

  dragend(event) {
  }
}
