import { Controller } from "stimulus";
import { PayjpError } from "../typing/payjp";

interface Payjp {
  setPublicKey: (publicKey: string) => void;
  createToken: (
    card: any,
    callback: (status: string, response: any) => void
  ) => void;
}

interface Window {
  Payjp: any;
}

declare var window: Window;

export default class extends Controller {
  payjp: any;
  numberElement: any;
  apiKeyTarget: HTMLInputElement;
  cardNameTarget: HTMLInputElement;
  cardNumberTarget: HTMLInputElement;
  cardExpire: HTMLInputElement;
  cardCvcTarget: HTMLInputElement;
  formTarget: HTMLFormElement;
  submitButtonTarget: HTMLButtonElement;

  static targets = [
    "apiKey",
    "cardName",
    "cardNumber",
    "cardExpire",
    "cardCvc",
    "cardToken",
    "form",
    "submitButton",
  ];
  initialize() {
    this.payjp = window.Payjp(this.apiKeyTarget.value);
    var elements4 = this.payjp.elements();
    this.numberElement = elements4.create("cardNumber");
    var expiryElement = elements4.create("cardExpiry");
    var cvcElement = elements4.create("cardCvc");
    this.numberElement.mount("#number-form");
    expiryElement.mount("#expiry-form");
    cvcElement.mount("#cvc-form");
  }
  connect() {}
  createToken(event: Event) {
    event.preventDefault();

    this.payjp.createToken(this.numberElement).then((r) => {
      if (r.error) {
        const error: PayjpError = r.error;
        if (error.code == "card_declined") {
          alert(
            "ご入力いただいたクレジットカードはカード会社から拒否されました。拒否理由についてはカード会社にお問い合わせください。\n\n" +
              "一般的には下記のようなことが考えられます。\n" +
              "・クレジットカードの限度額を超える決済である\n" +
              "・カードの有効期限が切れている\n" +
              "・定期課金途中で再発行などによるクレジットカード番号の変更があった\n" +
              "・プリペイド／デビットカードの残高が不足している\n" +
              "・プリペイド／デビットカードの発行会社の設定により決済ができない\n" +
              "・セキュリティの関係上、カード会社で決済に保留がかかった\n" +
              "・クレジットカードが利用できない／無効な状態である\n"
          );
        } else if (error.code == "expired_card") {
          alert(
            "ご入力いただいたクレジットカードは有効期限切れのカードの為、カード会社から拒否されました。詳細な拒否理由についてはカード会社にお問い合わせください。\n\n"
          );
        } else {
          alert(
            "クレジットカードが見つかりませんでした。入力内容をもう一度ご確認ください。"
          );
        }
        console.log(r.error);
        this.submitButtonTarget.removeAttribute("disabled");
      } else {
        const token = r.id;
        const tokenInput = document.createElement("input");
        tokenInput.setAttribute("type", "hidden");
        tokenInput.setAttribute("name", "payjpToken");
        tokenInput.setAttribute("value", token);
        this.formTarget.appendChild(tokenInput);
        console.log(r.token);
        this.formTarget.submit();
      }
    });
  }
}
