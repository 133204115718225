import { Controller } from "stimulus";

// ref: https://qiita.com/mimoe/items/855c112625d39b066c9a
const hiraToKana = (hira: string): string => {
  return hira.replace(/[\u3041-\u3096]/g, (match) => {
    return String.fromCharCode(match.charCodeAt(0) + 0x60);
  });
};

export default class extends Controller {
  formTarget: HTMLInputElement;
  resultTarget: HTMLElement;
  contactTarget: HTMLElement;
  static targets = ["form", "result", "contact"];
  connect() {
    this.reset();
  }
  search(event: Event) {
    event.preventDefault();
    const value = this.formTarget.value;
    if (value.length < 1) {
      return;
    }
    event.target.dispatchEvent(new Event("wizardNext"));
    const result = this.data.get("mets").split(",").indexOf(hiraToKana(value));
    if (result == -1) {
      this.resultTarget.innerHTML = `${value}さんは参加予定ではありません`;
    } else {
      this.resultTarget.innerHTML = `${value}さんは参加予定です<br/>
      女医コンの際中はチームでお話しします。なるべく一対一の会話にならないよう運営しておりますが、
      もし不安でしたら幹事の薬師寺まで「ショートメール」でご相談下さい(参加調整が可能かもしれません)。<br/>
      `;
      this.contactTarget.style.display = "block";
    }
  }
  reset() {
    this.formTarget.value = "";
    this.contactTarget.style.display = "none";
  }
}
