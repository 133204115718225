import { Controller } from "stimulus"

 
export default class extends Controller {
  delegate(event: Event) {
    if (event.target instanceof HTMLElement && event.target.dataset.target) {
      const target = document.getElementById(event.target.dataset.target);
      if (target instanceof HTMLElement) {
        target.click();
      }
    }
  }
}
