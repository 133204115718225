import { Controller } from "stimulus";

export default class extends Controller {
  approvedPartyFormTarget: Element;
  approvedPartySelectFormTarget: HTMLSelectElement;
  static targets = ["approvedPartyForm", "approvedPartySelectForm"];

  connect() {
    this.toggleApprovedPartyFormTarget(this.data.get("status"));
  }
  switchApprovedPartyForm(event) {
    this.toggleApprovedPartyFormTarget(event.target.value);
  }
  toggleApprovedPartyFormTarget(status) {
    if (status == "approved") {
      this.approvedPartyFormTarget.classList.remove("hide");
    } else {
      this.approvedPartyFormTarget.classList.add("hide");
    }
  }
  confirm(event: Event) {
    if (
      !(event.target instanceof HTMLInputElement) ||
      event.target.value != "approved"
    ) {
      return;
    }
    if (JSON.parse(this.data.get('invalidRequirement')) &&
      !confirm("年齢制限を超えていますが、承認してよろしいですか？")) {
        event.preventDefault();
        return false;
    }
    if (JSON.parse(this.data.get('blockedUser')) && !confirm("ブラックリストに登録されているユーザーですが、承認してよろしいですか？")) {
      event.preventDefault();
      return false;
    }
    console.log(this.data.get('highFrequency'));
    if (JSON.parse(this.data.get('highFrequency')) && !confirm("前回から1ヶ月以内の参加ですが、承認してよろしいですか？")) {
      event.preventDefault();
      return false;
    }
  }
}
