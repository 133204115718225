import { Controller } from "stimulus"
import { SyntheticEvent } from "react";

export default class extends Controller {
  specialityFormTargets: HTMLFormElement[]
  jobCategoryOtherFormTarget: HTMLFormElement

  static targets = ['specialityForm', 'jobCategoryOtherForm'];

  connect() {
    this.updateSpecialityForm(this.data.get('job-category'));
  }

  updateSpecialityForm(jobCategoryId: String) {
    this.hideAndDisableAllSpecialityFormTargets();
    this.showSpecialityFormTarget(jobCategoryId);
    if (jobCategoryId == this.data.get('job-category-other-id')) {
      this.jobCategoryOtherFormTarget.style.display = 'block';
      this.jobCategoryOtherFormTarget.querySelector('input').disabled = false;
    }
  }

  hideAndDisableAllSpecialityFormTargets() {
    this.specialityFormTargets.forEach((specialityForm) => {
      specialityForm.style.display = 'none';
      specialityForm.querySelector('select').disabled = true;
    });
    this.jobCategoryOtherFormTarget.style.display = 'none';
    this.jobCategoryOtherFormTarget.querySelector('input').disabled = true;
  }

  showSpecialityFormTarget(jobCategoryId: String) {
    const selectedJobCategorySpecialityForm = this.specialityFormTargets.find((specialityForm) => specialityForm.dataset.jobCategoryId == jobCategoryId);
    if (selectedJobCategorySpecialityForm) {
      selectedJobCategorySpecialityForm.style.display = 'block';
      selectedJobCategorySpecialityForm.querySelector('select').disabled = false;
    }
  }

  update(event: Event) {
    if (event.target instanceof HTMLSelectElement) {
      this.updateSpecialityForm(event.target.value);
    }
  }

}
