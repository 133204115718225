import StimulusFlatpickr from "stimulus-flatpickr";
import confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate";
// import a theme (could be in your main CSS entry too...)
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/plugins/confirmDate/confirmDate.css"

// import the translation files and create a translation mapping
import { Japanese } from "flatpickr/dist/l10n/ja.js";

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends StimulusFlatpickr {
  config = {};
  connect() {
    //define locale and global flatpickr settings for all datepickers
    this.config = {
      locale: Japanese,
      showMonths: 2,
      plugins: [confirmDatePlugin({
        confirmIcon: "", // your icon's html, if you wish to override
        confirmText: "決定",
        showAlways: false,
        theme: "light",
      })]
    };

    super.connect();
  }
}
