import { Controller } from "stimulus";
import { inputChange } from "./textarea_submit_controller";

export default class extends Controller {
  countFormTarget: HTMLTextAreaElement;
  counterTarget: HTMLElement;
  hasCountFormTarget: boolean;
  static targets = ["countForm", "counter"];

  connect() {
    if (this.hasCountFormTarget) {
      inputChange(this.countFormTarget, (currentValue: string) =>
        this.count(currentValue)
      );
      this.count(this.countFormTarget.value);
    }
  }

  count(value: string) {
    this.counterTarget.innerHTML = `${value.length}`;
  }
}
