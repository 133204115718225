import { Controller } from "stimulus"

export default class extends Controller {
  originalImageTarget: HTMLElement;
  presentOriginalTriggerTarget: HTMLElement;
  dismissOriginalTriggerTarget: HTMLElement;
  resizeImageTarget: HTMLElement;
  static targets = ['originalImage', 'resizeImage', 'presentOriginalTrigger', 'dismissOriginalTrigger'];
  presentOriginal() {
    this.originalImageTarget.style.display = 'block';
    this.resizeImageTarget.style.display = 'none';
    this.presentOriginalTriggerTarget.style.display = 'none';
    this.dismissOriginalTriggerTarget.style.display = 'block';
  }
  dismissOriginal() {
    this.originalImageTarget.style.display = 'none';
    this.resizeImageTarget.style.display = 'block';
    this.presentOriginalTriggerTarget.style.display = 'block';
    this.dismissOriginalTriggerTarget.style.display = 'none';
  }
}
