import { Controller } from "stimulus";

export const inputChange = (
  element: HTMLTextAreaElement,
  callback?: (value: string) => void
) => {
  let lastValue = "";
  let focusTimer = 0;
  const watch = () => {
    const currentValue = element.value;
    if (lastValue != currentValue) {
      element.dispatchEvent(new Event("inputChange"));
      if (callback) {
        callback(currentValue);
      }
    }
    lastValue = currentValue;
    focusTimer = window.setTimeout(() => watch(), 200);
  };
  element.addEventListener("focus", () => {
    watch();
  });
  element.addEventListener("blur", () => {
    lastValue = element.value;
    window.clearTimeout(focusTimer);
    focusTimer = 0;
  });
};

export default class extends Controller {
  textareaTarget: HTMLTextAreaElement;
  submitTarget: HTMLButtonElement;
  static targets = ["textarea", "submit"];
  connect() {
    this.validation();
    inputChange(this.textareaTarget);
  }

  validation() {
    if (this.textareaTarget.value == "") {
      this.disableSubmit();
    } else {
      this.enableSubmit();
    }
  }
  enableSubmit() {
    this.submitTarget.disabled = false;
  }
  disableSubmit() {
    this.submitTarget.disabled = true;
  }
}
