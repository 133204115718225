import { Controller } from "stimulus";
import Sortable from 'sortablejs';

export default class extends Controller {
  sortableTarget: HTMLInputElement;
  hasSortableTarget: boolean;
  static targets = ["sortable"];

  connect() {
    if (this.hasSortableTarget) {
      const sortable = Sortable.create(this.sortableTarget, {
        animation: 150,
        ghostClass: 'sortable-ghost',
      });
    }
  }
}
