import { Controller } from "stimulus";

export default class extends Controller {
  heightAndWeightTarget: Element;
  modalContainerTarget: Element;
  ageFormTarget: HTMLInputElement;
  birthdateSelectTargets: HTMLSelectElement[];
  genderFormTargets: HTMLInputElement[];
  static targets = [
    "heightAndWeight",
    "ageForm",
    "modalContainer",
    "genderForm",
    "birthdateSelect"
  ];

  connect() {
    this.toggleHeightAndWeight(this.data.get("gender"));
  }
  switchHeightAndWeight(event) {
    this.toggleHeightAndWeight(event.target.value);
  }
  toggleHeightAndWeight(gender) {
    if (gender == "male") {
      this.heightAndWeightTarget.classList.remove("hide");
    } else {
      this.heightAndWeightTarget.classList.add("hide");
    }
  }
  check_age(event: Event) {
    const noAgeLimit = JSON.parse(this.data.get("party-no-age-limit"));
    if (noAgeLimit) {
      return true;
    }
    const checkedGenderForm = this.genderFormTargets.find(
      radio => radio.checked
    );
    if (!checkedGenderForm) {
      return true;
    }
    if (!this.filledInValidBirthday()) {
      return false;
    }
    const gender = checkedGenderForm.value;
    const age = this.age();
    const party_rating_age_for_men = this.data.get("party-rating-age-for-men");
    const party_rating_age_for_women = this.data.get(
      "party-rating-age-for-women"
    );

    if (!gender || !age) {
      return true;
    }

    if (
      (gender == "male" &&
        age > parseInt(party_rating_age_for_men)) ||
      (gender == "female" &&
        age > parseInt(party_rating_age_for_women))
    ) {
      event.preventDefault();
      this.showModal();
    }
  }
  dismissModal() {
    this.modalContainerTarget.classList.remove("is-active");
  }
  showModal() {
    this.modalContainerTarget.classList.add("is-active");
  }
  filledInBirthday() {
    return `${this.birthdateSelectTargets[0].value}-${this.birthdateSelectTargets[1].value}-${this.birthdateSelectTargets[2].value}`;
  }
  age() {
    if (!this.filledInValidBirthday()) {
      return null;
    }
    const ageDifMs = Date.now() - new Date(this.filledInBirthday()).getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
  filledInValidBirthday() {
    const birthday = this.filledInBirthday();
    console.log(this.birthdateSelectTargets);
    const dateReg = /^\d{4}-\d{1,2}-\d{1,2}$/;
    return birthday.match(dateReg) !== null;
  }
}
