import { Controller } from "stimulus";

export default class extends Controller {
  form: HTMLFormElement;
  formItemTargets: HTMLInputElement[];
  focusedValue: string;
  static targets = ["formItem"];
  connect() {
    const element = this.element;
    if (element instanceof HTMLFormElement) {
      this.form = element;
      this.addOnChangeEventListener();
    } else {
      console.warn(
        "このコントローラはformタグに使用することを前提に実装しています。ご確認ください。"
      );
    }
  }
  addOnChangeEventListener() {
    this.formItemTargets.forEach((target) => {
      target.addEventListener("focus", (event: Event) => {
        console.log('hello')
        if (event.target instanceof HTMLSelectElement) {
          this.focusedValue = event.target.value;
        }
      });

      target.addEventListener("change", (event: Event) => {
        const target = event.target;
        if (target instanceof HTMLSelectElement) {
          const selectedOption = target.options[target.selectedIndex];
          if (!selectedOption) {
            return;
          }
          const selectedOptionLabel = selectedOption.innerText;
          if (
            confirm(`「${selectedOptionLabel}」に変更します。よろしいですか？`)
          ) {
            this.form.submit();
          } else {
            target.value = this.focusedValue;
            event.preventDefault();
            return false;
          }
        }
      });
    });
  }
}
