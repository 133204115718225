import { Controller } from "stimulus";

export default class extends Controller {
  favorCount: number;
  favorLimit: number;
  isMember: boolean;
  publishMatchingResults: boolean;
  modalTarget: HTMLElement;
  remainingFavorTarget: HTMLElement;
  extraFavorTargets: HTMLElement[];
  static targets = ['modal', 'remainingFavor', 'extraFavor'];

  connect() {
    this.favorCount = parseInt(this.data.get("favorsCount"));
    this.favorLimit = parseInt(this.data.get("favorsLimit"));
    this.isMember = JSON.parse(this.data.get("member"))
    this.publishMatchingResults = JSON.parse(this.data.get("publishMatchingResults"));
  }

  canFavor(): boolean {
    return this.canEdit() && this.favorCount < this.favorLimit;
  }

  canEdit(): boolean {
    return this.isMember || !this.publishMatchingResults;
  }

  favor(event: Event) {
    if (!this.canFavor()) {
      event.preventDefault();
      this.presentModal(event);
      return;
    }
    if (this.publishMatchingResults && !confirm('ハートの開票しているので即時公開となり、取り消しができません。よろしいでしょうか？')) {
      return false;
    }
    this.countUpFavor();
    this.triggerEvent(event.currentTarget, 'favor');
    if (this.publishMatchingResults && this.favorLimit - this.favorCount == 0) {
      this.extraFavorTargets.forEach((extraFavorTarget) => extraFavorTarget.style.display = 'none')
    }
  }

  unfavor(event: Event) {
    if (!this.canEdit()) {
      event.preventDefault();
      this.presentModal(event);
      return;
    }
    this.countDownFavor();
    this.triggerEvent(event.currentTarget, 'unfavor');
  }

  triggerEvent(target: EventTarget, eventName: string) {
    const event = document.createEvent("HTMLEvents");
    event.initEvent(eventName, true, true);
    target.dispatchEvent(event);
  }

  presentModal(event: Event) {
    this.modalTarget.classList.add('is-active');
  }
  dismissModal(event: Event) {
    event.preventDefault();
    this.modalTarget.classList.remove('is-active');
  }
  countUpFavor() {
    this.favorCount++;
    this.updateRemaingFavor();
  }
  countDownFavor() {
    this.favorCount--;
    this.updateRemaingFavor();
  }
  updateRemaingFavor() {
    this.remainingFavorTarget.innerHTML = `${this.favorLimit - this.favorCount}`;
  }
}
