import { Controller } from "stimulus";
import { DateTime } from "luxon";

export default class extends Controller {
  startsAtFieldTarget: HTMLFormElement;
  perDurationFieldTarget: HTMLFormElement;
  iterationNumberFieldTarget: HTMLFormElement;
  previewContainerTarget: HTMLElement;
  static targets = [
    "startsAtField",
    "perDurationField",
    "iterationNumberField",
    "previewContainer"
  ];

  connect() {
    this.startsAtFieldTarget.value = DateTime.local().toFormat(
      "yyyy-MM-dd'T'HH:mm"
    );
    this.renderPreview();
  }

  renderPreview() {
    const startsAt = DateTime.fromISO(this.startsAtFieldTarget.value);
    const perDuration = parseInt(this.perDurationFieldTarget.value);
    const iterationNumber = parseInt(this.iterationNumberFieldTarget.value);
    this.previewContainerTarget.innerHTML = "";
    for (let i = 0; i < iterationNumber; i++) {
      const listElement = document.createElement("li");
      const iterationStartsAt = startsAt.plus({ minutes: perDuration * i });
      const iterationEndsAt = iterationStartsAt.plus({
        minutes: perDuration - 1
      });
      listElement.innerHTML = `${iterationStartsAt.toLocaleString(
        DateTime.TIME_24_SIMPLE
      )} - ${iterationEndsAt.toLocaleString(DateTime.TIME_24_SIMPLE)}`;
      this.previewContainerTarget.appendChild(listElement);
    }
  }

  refreshStartsAtNow(event: Event) {
    event.preventDefault();
    this.startsAtFieldTarget.value = DateTime.local().toFormat(
      "yyyy-MM-dd'T'HH:mm"
    );
    this.renderPreview();
  }
}
