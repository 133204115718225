import { Controller } from "stimulus";

export default class extends Controller {
  index: number;
  pageTargets: HTMLElement[];
  formFieldTargets: HTMLInputElement[];
  inputConfirmTargets: HTMLElement[];
  formTarget: HTMLFormElement;
  validateTargets: HTMLInputElement[];
  hasValidateTarget: boolean;
  submited = false;
  invalidForms: HTMLInputElement[];
  resetConfirmText = true;
  static targets = ["page", "formField", "inputConfirm", "form", "validate"];

  connect() {
    this.showPage(0);
    if (this.data.get('resetConfirmText') == 'false')
    this.resetConfirmText = this.data.get('resetConfirmText') != 'false'
  }

  next(event: Event) {
    event.preventDefault();
    if (this.hasValidateTarget && !this.valid()) {
      this.displayErrors();
      return;
    }
    const nextPage = this.nextPageBy(event);
    this.showPage(nextPage);
  }

  nextPageBy(event: Event) {
    if (event.target instanceof HTMLElement && event.target.dataset.nextPage) {
      return parseInt(event.target.dataset.nextPage) - 1;
    }
    return this.index + 1;
  }

  prev(event: Event) {
    event.preventDefault();
    this.showPage(this.index - 1);
  }

  fillIn(event: Event) {
    const fillInFields = this.formFieldTargets.filter(element => {
      if (
        event.target instanceof HTMLElement &&
        event.target.dataset.fillInField.indexOf(element.name) >= 0
      ) {
        return true;
      }
      return false;
    });
    if (fillInFields.length > 0 && event.target instanceof HTMLElement) {
      fillInFields.forEach(field => {
        if (event.target instanceof HTMLElement)
          if (event.target.dataset.fillInMethod == "append") {
            field.value += this.fillInValue(event.target);
          } else {
            field.value = this.fillInValue(event.target as HTMLElement);
          }
      });
    }
    const fillInConfirm = this.inputConfirmTargets.find(element => {
      if (
        event.target instanceof HTMLElement &&
        event.target.dataset.fillInField == element.dataset.inputField
      ) {
        return true;
      }
      return false;
    });
    if (fillInConfirm && event.target instanceof HTMLTextAreaElement) {
      fillInConfirm.innerHTML = event.target.value.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }
    if (fillInConfirm && event.target instanceof HTMLInputElement) {
      fillInConfirm.innerHTML = event.target.value;
    }
  }

  fillInValue(element: HTMLElement) {
    const valueText = element.dataset.fillInValue;
    if (/^form->/.test(valueText)) {
      const valueTextElement = document.querySelector(valueText.substring(6));
      if (valueTextElement instanceof HTMLTextAreaElement) {
        return valueTextElement.value;
      }
    }
    return valueText;
  }

  fillInAndSubmit(event: Event) {
    this.fillIn(event);
    this.submit();
  }

  showPage(index: number) {
    this.index = index;
    this.pageTargets.forEach((element, i) => {
      element.classList.toggle("page-current", index == i);
    });
  }

  valid(): boolean {
    this.invalidForms = this.validateTargets.filter(validateTarget => {
      return validateTarget.dataset.validate && validateTarget.dataset.validate == 'required' && validateTarget.value == ''
    });
    return this.invalidForms.length == 0;
  }
  displayErrors() {
    this.invalidForms.forEach(form => {
      form.classList.add('is-danger');
      const note = form.nextElementSibling;
      if (note instanceof HTMLElement) {
        note.style.display = 'block';
      }
    });
  }
  submit() {
    if (this.hasValidateTarget && !this.valid()) {
      this.displayErrors();
      return;
    }
    if (!this.submited) {
      this.submited = true;
      this.formTarget.submit();
    }
  }

  reset() {
    this.showPage(0);
    this.formFieldTargets.forEach(element => {
      element.value = element.dataset.default || "";
    });
    if (this.resetConfirmText) {
        this.inputConfirmTargets.forEach(element => {
          element.innerHTML = "";
        });
    }
  }
}
