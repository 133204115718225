import { Controller } from "stimulus";

export default class extends Controller {
  formTarget: HTMLInputElement;
  candidateTargets: HTMLElement[];
  watchTimer: number;
  lastValue: string;
  watchInterval = 500;
  static targets = ["form", "candidate"];

  connect() {
    this.formTarget.addEventListener("blur", () => {
      if (this.formTarget.classList.contains('with-candidates')) {
        window.setTimeout(() => this.dismiss(), 500);
      }
      else {
        this.dismiss();
      }
    });
  }

  disconnect() {
    this.stopWatch();
  }

  startWatch() {
    this.watch();
  }

  stopWatch() {
    clearTimeout(this.watchTimer);
    this.watchTimer = 0;
  }

  watch() {
    const currentValue = this.formTarget.value;
    if (currentValue != this.lastValue) {
      let hasCandidates = false;
      this.candidateTargets.forEach(candidateTarget => {
        if (
          currentValue.length == 0 ||
          candidateTarget.dataset.candidateText.indexOf(currentValue) == -1
        ) {
          candidateTarget.style.display = "none";
        } else {
          candidateTarget.style.display = "block";
          hasCandidates = true;
        }
      });
      if (hasCandidates) {
        this.formTarget.classList.add("with-candidates");
      } else {
        this.formTarget.classList.remove("with-candidates");
      }
      this.lastValue = currentValue;
    }
    this.watchTimer = window.setTimeout(() => this.watch(), this.watchInterval);
  }

  setValue(event: Event) {
    if (event.target instanceof HTMLElement) {
      this.formTarget.value = event.target.dataset.value;
    }
    this.dismiss();
  }

  dismiss() {
    this.candidateTargets.forEach(element => (element.style.display = "none"));
    this.formTarget.classList.remove("with-candidates");
    this.lastValue = this.formTarget.value;
    this.stopWatch();
  }
}
