import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.classList.remove("is-active");
  }
  toggle(event) {
    event.preventDefault();
    this.element.classList.toggle("is-active");
  }
  dismiss(event) {
    if (!event.relatedTarget || !event.relatedTarget.href) {
      this.element.classList.remove("is-active");
    }
  }
}
