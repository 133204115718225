import { Controller } from "stimulus"

export default class extends Controller {
  inputTargets: HTMLInputElement[]
  submitButtonTarget: HTMLInputElement
  static targets = ['input', 'submitButton'];
  check() {
    const checkedInput = this.inputTargets.filter((element) => element.value == 'true');
    if (checkedInput.length >= parseInt(this.data.get('limit'))) {
      this.submitButtonTarget.disabled = true
    }
  }

  uncheck() {
    const checkedInput = this.inputTargets.filter((element) => element.value == 'true');
    if (checkedInput.length <= parseInt(this.data.get('limit'))) {
      this.submitButtonTarget.disabled = false
    }

  }
}
