// pagy_controller.js - a stimulus JS controller
// import { Controller } from "@hotwired/stimulus"
import { Controller } from "stimulus";

export default class extends Controller {
  formTarget: HTMLFormElement;
  pageInputTarget: HTMLInputElement;
  static targets = ["form", "pageInput" ]

  connect() {
  }

  submitForm(event){
    event.preventDefault()
    let pageNumber = event.target.getAttribute("href")
    if (pageNumber === "#") {}
    else {
      let pageInput = this.pageInputTarget
      pageInput.value = pageNumber

      this.formTarget.requestSubmit()
    }
  }
}
