import { Controller } from "stimulus";
import { DateTime } from "luxon";

interface Schedule {
  starts_at: string;
  ends_at: string;
}

interface DateTimeSchedule {
  startsAt: DateTime;
  endsAt: DateTime;
}

function isScheduleArray(
  scheduleArray: Array<any>
): scheduleArray is Array<Schedule> {
  for (let i of scheduleArray) {
    if (i.starts_at == undefined || i.ends_at == undefined) {
      return false;
    }
  }
  return true;
}

export default class extends Controller {
  timerTarget: HTMLElement;
  containerTarget: HTMLElement;
  static targets = ["timer", "container"];
  connect() {
    this.renderCurrentSchedule();
  }

  renderCurrentSchedule() {
    const currentSchedule = this.currentSchedule();
    if (
      currentSchedule &&
      currentSchedule.startsAt !== undefined &&
      currentSchedule.endsAt !== undefined
    ) {
      this.renderRemaining(currentSchedule);
    } else {
      this.containerTarget.style.display = "none";
    }
  }

  currentSchedule() {
    const scheduleData = JSON.parse(this.data.get("schedule"));
    if (isScheduleArray(scheduleData)) {
      const schedule: Array<Schedule> = scheduleData;
      const nowDt = DateTime.local();
      for (let i of schedule) {
        const startsAtDt = DateTime.fromISO(i.starts_at);
        const endsAtDt = DateTime.fromISO(i.ends_at);
        if (nowDt > startsAtDt && nowDt < endsAtDt) {
          return { startsAt: startsAtDt, endsAt: endsAtDt };
        }
      }
    }
  }

  renderRemaining(schedule: DateTimeSchedule) {
    const remaining = schedule.endsAt.diff(DateTime.local(), [
      "seconds",
      "minutes"
    ]);
    if (remaining.seconds < 0) {
      this.containerTarget.classList.add("is-warning");
      this.containerTarget.classList.remove("is-danger");
    return this.renderCurrentSchedule();
    } else {
      if (remaining.minutes < 2) {
        this.containerTarget.classList.remove("is-warning");
        this.containerTarget.classList.add("is-danger");
      }
      const remainingSeconds = `${Math.floor(
        remaining.toObject().seconds
      )}`.padStart(2, "0");
      this.timerTarget.innerHTML = `${
        remaining.toObject().minutes
      }:${remainingSeconds}`;
      setTimeout(() => {
        this.renderRemaining(schedule);
      }, 1000);
    }
  }
}
