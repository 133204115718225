import { Controller } from "stimulus";

const isVisible = (element: HTMLElement) => {
  const scroll = window.scrollY || window.pageYOffset;
  const boundsTop = element.getBoundingClientRect().top + scroll;

  const viewport = {
    top: scroll,
    bottom: scroll + window.innerHeight
  };

  const bounds = {
    top: boundsTop,
    bottom: boundsTop + element.clientHeight
  };

  return (
    (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
    (bounds.top <= viewport.bottom && bounds.top >= viewport.top)
  );
};

export default class extends Controller {
  fetchTriggerTarget: HTMLElement;
  hasFetchTriggerTarget: boolean;
  fetchFormTarget: HTMLFormElement;
  messagesTarget: HTMLElement;
  fulfilledAllMessagesNorificationTarget: HTMLElement;
  hasFulfilledAllMessagesNorificationTarget: boolean;
  scrollToTopButtonTarget: HTMLButtonElement;
  hasScrollToTopButtonTarget: boolean;
  fetching: boolean = false;
  fulfilledAllMessages: boolean = false;
  
  page: number = 1;
  handleEvent: EventListener;
  static targets = ["fetchTrigger", "fetchForm", "messages", "fulfilledAllMessagesNorification", "scrollToTopButton"];

  connect() {
    if (this.hasFetchTriggerTarget) {
      this.handleEvent = () => { this.fetch() }
      window.addEventListener("scroll", this.handleEvent);
    }
    if (this.hasFulfilledAllMessagesNorificationTarget) {
      this.fulfilledAllMessagesNorificationTarget.style.display = 'none';
    }
    if (this.hasScrollToTopButtonTarget) {
      this.scrollToTopButtonTarget.addEventListener('click', () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera        
      })
    }
    if (this.data.get('displayAllMessages') == 'true') {
      this.fetchTriggerTarget.style.display = 'none';
    }
  }

  fetch() {
    if (!isVisible(this.fetchTriggerTarget)) {
      return;
    }
    if (this.fetching) {
      return;
    }
    if (this.fulfilledAllMessages) {
      return;
    }
    this.fetching = true;
    this.page++;
    const url = new URL(this.data.get("url"));
    url.searchParams.append("page", `${this.page}`);
    fetch(url.href)
      .then(response => response.json())
      .then((responseJson: any) => {
        this.messagesTarget.insertAdjacentHTML("beforeend", responseJson.html);
        this.fetching = false;
        if (responseJson.lastPage) {
          this.fulfilledAllMessages = true;
          window.removeEventListener('scroll', this.handleEvent);
          this.fetchTriggerTarget.style.display = 'none';
          this.fulfilledAllMessagesNorificationTarget.style.display = 'block';
        }
      });
  }
}
