import { Controller } from "stimulus";

export default class extends Controller {
  modalContainerTarget: Element;
  tmpPartyIdRadioButtonTargets: HTMLInputElement[];
  partyIdRadioButtonTarget: HTMLInputElement;
  emptyDescriptionTarget: HTMLElement;
  filledInDescriptionTarget: HTMLElement;
  filledInPartyNameTarget: HTMLElement;
  filledInPartyUrlTarget: HTMLAnchorElement;
  userIdTarget: HTMLInputElement;
  resultTarget: HTMLElement;
  static targets = [
    "modalContainer",
    "tmpPartyIdRadioButton",
    "partyIdRadioButton",
    "emptyDescription",
    "filledInDescription",
    "filledInPartyName",
    "filledInPartyUrl",
    "userId",
    "result",
  ];
  connect() {
    if (this.partyIdRadioButtonTarget.value === "") {
      this.filledInDescriptionTarget.style.display = "none";
    } else {
      const filledInRadioButton = this.tmpPartyIdRadioButtonTargets.find(
        (radioButton) =>
          radioButton.value === this.partyIdRadioButtonTarget.value
      );
      if (filledInRadioButton) {
        this.showFilledInDescription(filledInRadioButton);
      }
    }
  }
  dismissModal(event?: Event) {
    if (event) {
      event.preventDefault();
    }
    this.tmpPartyIdRadioButtonTargets.forEach(
      (radioButton) => (radioButton.checked = false)
    );
    this.modalContainerTarget.classList.remove("is-active");
  }

  showModal(event: Event) {
    event.preventDefault();
    if (this.partyIdRadioButtonTarget.value) {
      const tmpPartyIdRadioButtonTarget =
        this.tmpPartyIdRadioButtonTargets.find(
          (radioButton) =>
            radioButton.value === this.partyIdRadioButtonTarget.value
        );
      if (tmpPartyIdRadioButtonTarget) {
        tmpPartyIdRadioButtonTarget.checked = true;
      }
    }
    this.modalContainerTarget.classList.add("is-active");
  }

  updateParty(event: Event) {
    event.preventDefault();
    const checkedRadioButton = this.tmpPartyIdRadioButtonTargets.find(
      (radioButton) => radioButton.checked
    );
    if (checkedRadioButton) {
      this.partyIdRadioButtonTarget.value = checkedRadioButton.value;
      this.showFilledInDescription(checkedRadioButton);
    }
    this.dismissModal();
  }

  showFilledInDescription(checkedRadioButton: HTMLInputElement) {
    this.filledInDescriptionTarget.style.display = "flex";
    this.emptyDescriptionTarget.style.display = "none";
    this.filledInPartyNameTarget.innerText =
      checkedRadioButton.dataset.partyTitle;
    this.filledInPartyUrlTarget.href = checkedRadioButton.dataset.partyUrl;
    this.filledInPartyUrlTarget.innerText = `${location.origin}/${checkedRadioButton.dataset.partyUrl}`;

    this.getData();
  }

  getData() {
    this.resultTarget.innerText = "（データ取得中）";
    const user_id = this.userIdTarget.value;
    const party_id = this.partyIdRadioButtonTarget.value;
    const url = "/service/met_check";
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    fetch(`${url}?user_id=${user_id}&party_id=${party_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.resultTarget.innerText = "";
        let table = document.createElement("table");
        let tableHead = document.createElement("thead");
        let tableBody = document.createElement("tbody");

        let row = document.createElement("tr");
        let cell1 = document.createElement("td");
        cell1.innerText = "日時 / タイトル";
        row.appendChild(cell1);
        let cell2 = document.createElement("td");
        cell2.innerText = "お会いした人";
        row.appendChild(cell2);
        tableHead.appendChild(row);

        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            let row = document.createElement("tr");
            let cell1 = document.createElement("td");
            cell1.innerHTML =
              data[i].dates + ` <a href=${data[i].url}>${data[i].title}</a>`;
            row.appendChild(cell1);
            let cell2 = document.createElement("td");
            cell2.innerHTML = ` <a href=${data[i].user_path}>${data[i].fullname}</a>`;
            row.appendChild(cell2);

            tableBody.appendChild(row);
          }
        } else {
          let row = document.createElement("tr");
          let cell1 = document.createElement("td");
          cell1.innerText = "対象データがありません";
          row.appendChild(cell1);
          tableBody.appendChild(row);
        }
        table.appendChild(tableHead);
        table.appendChild(tableBody);
        this.resultTarget.appendChild(table);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }
}
