/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "@stimulus/polyfills";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import Clipboard from "clipboard";

interface Window {
  LineIt: any;
}

declare var window: Window;

const application = Application.start();
const context = require.context("../controllers", true, /.ts$/);
application.load(definitionsFromContext(context));
document.addEventListener("turbolinks:load", () => {
  // 誕生日の選択フォームを .select で囲う(bulma.cssが効くように)
  const wrap = (element: HTMLElement, wrapElement: HTMLElement) => {
    if (element.style.display === "none") {
      wrapElement.style.display = "none";
    }
    element.parentNode.insertBefore(wrapElement, element);
    wrapElement.appendChild(element);
  };

  const matches = document.querySelectorAll(".wrapped-select");
  matches.forEach((matche) => {
    const selectWrapElement = document.createElement("div");
    selectWrapElement.classList.add("select");
    if (matche instanceof HTMLElement) {
      wrap(matche, selectWrapElement);
    }
  });

  const clipboard = new Clipboard(".clipable");
});
