import { Controller } from "stimulus";
import { StimulusEvent } from "../../typing/stimulus";

type ProfileOpenToState = "entries_matchmaking_only" | "everyone" | "no_one";
interface ProfilePrivacyFormState {
  picture: ProfileOpenToState;
  hometown: ProfileOpenToState;
  residential_prefecture: ProfileOpenToState;
  job_category: ProfileOpenToState;
  speciality: ProfileOpenToState;
  age: ProfileOpenToState;
  school: ProfileOpenToState;
  note: ProfileOpenToState;
  work: ProfileOpenToState;
  haunt: ProfileOpenToState;
  age_range: ProfileOpenToState;
  parent_occupation: ProfileOpenToState;
  annual_income: ProfileOpenToState;
  first_marriage: ProfileOpenToState;
  having_children: ProfileOpenToState;
  marital_status: ProfileOpenToState;
  family_structure: ProfileOpenToState;
  height: ProfileOpenToState;
  body_weight: ProfileOpenToState;
}
const displayStyle = {
  picture: "block",
  hometown: "table-row",
  residential_prefecture: "block",
  job_category: "block",
  speciality: "block",
  age: "block",
  school: "block",
  note: "block",
  work: "table-row",
  haunt: "table-row",
  age_range: "table-row",
  parent_occupation: "table-row",
  annual_income: "table-row",
  first_marriage: "table-row",
  having_children: "table-row",
  height: "block",
  body_weight: "block",
  marital_status: "table-row",
  family_structure: "table-row",
};
export default class extends Controller {
  fieldTargets: HTMLSelectElement[];
  previewElementTargets: HTMLElement[];
  state: ProfilePrivacyFormState;
  everyonePreviewContainerTarget: HTMLElement;
  restrictEveryonePreviewContainerTarget: HTMLElement;

  static targets = [
    "field",
    "previewElement",
    "everyonePreviewContainer",
    "restrictEveryonePreviewContainer",
  ];
  connect() {
    this.fieldTargets.forEach((field) => {
      const name = field.name.match(/\[(\w+)\]/)[1];
      const { value } = field;
      // this.updatePreviewOf(name, value as ProfileOpenToState);
      this.state = { ...this.state, [name]: value };
    });
    console.log(this.state);
    this.renderPreview();
  }
  renderPreview() {
    const keys = Object.keys(this.state);
    keys.map((key) => {
      this.updatePreviewOf(key, this.state[key]);
    });
    if (keys.some((key) => this.state[key] === "everyone")) {
      this.everyonePreviewContainerTarget.style.display = "block";
      this.restrictEveryonePreviewContainerTarget.style.display = "none";
    } else {
      this.everyonePreviewContainerTarget.style.display = "none";
      this.restrictEveryonePreviewContainerTarget.style.display = "block";
    }
  }
  updatePreview({ target }: StimulusEvent) {
    const name = target.name.match(/\[(\w+)\]/)[1];
    const { value } = target;
    this.state = { ...this.state, [name]: value };
    this.renderPreview();
  }
  updatePreviewOf(name: string, openTo: ProfileOpenToState) {
    const elements = this.previewElementTargets.filter(
      (element) => element.dataset.previewName === name
    );
    console.log(elements);
    const previewElementsForEnteredMatchmaking = elements.filter(
      (element) => element.dataset.previewType === "enteredMatchmaking"
    );
    const previewElementsForEveryone = elements.filter(
      (element) => element.dataset.previewType === "everyone"
    );
    console.log(
      previewElementsForEnteredMatchmaking,
      previewElementsForEveryone,
      openTo
    );
    if (openTo === "entries_matchmaking_only") {
      previewElementsForEveryone.forEach(
        (element) => (element.style.display = "none")
      );
      previewElementsForEnteredMatchmaking.forEach(
        (element) => (element.style.display = displayStyle[name])
      );
    } else if (openTo === "everyone") {
      previewElementsForEveryone.forEach(
        (element) => (element.style.display = displayStyle[name])
      );
      previewElementsForEnteredMatchmaking.forEach(
        (element) => (element.style.display = displayStyle[name])
      );
    } else if (openTo === "no_one") {
      previewElementsForEveryone.forEach(
        (element) => (element.style.display = "none")
      );
      previewElementsForEnteredMatchmaking.forEach(
        (element) => (element.style.display = "none")
      );
    }
  }
}
